import React from "react";
import Seo from "./seo";

export const PageMeta = ({
  metaImage,
  metaDescription,
  metaTitle,
  metaKeywords,
  robots,
}) => {
  return (
    <Seo
      title={metaTitle}
      description={metaDescription}
      keywords={metaKeywords}
      metaRobots={robots}
      image={metaImage}
    />
  );
};
